// create a new enum for translations to easily acess tranlations in the app

export enum TranslationsEnum {
  "AED" = "AED",
  "PaymentTitle" = "PaymentTitle",
  "Cash" = "Cash",
  "Credit" = "Credit",
  "MyOrders" = "MyOrders",
  "English" = "English",
  "German" = "German",
  "French" = "French",
  "Arabic" = "Arabic",
  "Chinese" = "Chinese",
  "Table" = "Table",
  "Reviews" = "Reviews",
  "Languages" = "Languages",
  "AddReview" = "AddReview",
  "Close" = "Close",
  "Submit" = "Submit",
  "Cancel" = "Cancel",
  "Confirm" = "Confirm",
  "Review" = "Review",
  "ChooseLanguage" = "ChooseLanguage",
  "Menu" = "Menu",
  "Home" = "Home",
  "PayNow" = "PayNow",
  "ViewMenu" = "ViewMenu",
  "MemberShip" = "MemberShip",
  "Split" = "Split",
  "PayBill" = "PayBill",
  "Track" = "Track",
  "EnterName" = "EnterName",
  "EnterYourName" = "EnterYourName",
  "AddTip" = "AddTip",
  "CustomTip" = "CustomTip",
  "TipDescription" = "TipDescription",
  "Pay" = "Pay",
  "CardPay" = "CardPay",
  "Copyright © 2023" = "Copyright © 2023",
  "Select" = "Select",
  "Item" = "Item",
  "Quantity" = "Qty",
  "Price" = "Price",
  "Total" = "Total",
  "TotalAmount" = "TotalAmount",
  "Paid" = "Paid",
  "Remaining" = "Remaining",
  "selectItemsPrompt" = "selectItemsPrompt",
  "enterNamePrompt" = "enterNamePrompt",
  "enterQtyPrompt" = "enterQtyPrompt",
  "EnterQty" = "EnterQty",
  "EnterQtyPlaceholder" = "EnterQtyPlaceholder",
  "EnterAmount" = "EnterAmount",
  "extratips" = "extratips",
  "payfor" = "payfor",
  "History" = "History",
  "Time" = "Time",
  "Customer" = "Customer",
  "Amount" = "Amount",
  "serviceFee" = "serviceFee",
  tax = "tax",
  PaymentSuccess = "PaymentSuccess",
  PaymentFailed = "PaymentFailed",
  tipAmount = "tipAmount",
  tipMatters = "tipMatters",
  earnCashback = "earnCashback",
  cashbackText = "cashbackText",
  otpinputPlaceholder = "OTPinputPlaceholder",
  otpModalTitle = "OTPModalTitle",
  otpResendText = "OTPResendText",
  otpResendLink = "OTPResendLink",
  otpError = "OTPError",
  otpResendError = "OTPResendError",
  otpVerifyError = "OTPVerifyError",
  sendOTP = "sendOTP",
  otpResendTime = "OTPResendTime",
  samsungPay = "samsungPay",
  welcomeWallet = "welcomeWallet",
  walletBalanceText = "walletBalanceText",
  useLoyalty = "useLoyalty",
  walletCredit = "walletCredit",
  splitBillTitle = "splitBillTitle",
  splitBillSubTitle = "splitBillSubTitle",
  splitBillSelect = "splitBillSelect",
  splitBillOption1 = "splitBillOption1",
  splitBillOption2 = "splitBillOption2",
  splitBillOption3 = "splitBillOption3",
  earnCashbackLanding = "earnCashbackLanding",
  slogan = "slogan",
  tableNo = "tableNo",
  invoiceNumber = "invoiceNumber",
  allPricesAreIncluded = "allPricesAreIncluded",
  payCustomTip = "payCustomTip",
  includesTip = "includesTip",
  payWithSoSocial = "payWithSoSocial",
  reduceYourBill = "reduceYourBill",
  paymentMethod = "paymentMethod",
  payWithCredit = "payWithCredit",
  authTitle = "authTitle",
  authVerify = "authVerify",
  TotalDiscountedAmount = "TotalDiscountedAmount",
  payCustomAmount = "payCustomAmount",
  redeemSoSocialCash = "redeemSoSocialCash",
  earnWithSoSocial = "earnWithSoSocial",
  earnedWithSoSocial = "earnedWithSoSocial",
  customTipInvoicePage = "customTipInvoicePage",
}
