import {
  Typography,
  Grid,
  Button,
  createTheme,
  Box,
  Link,
  SxProps,
  CircularProgress,
} from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import { FaChevronLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import lightThemeOptions from "../../theme/lightThemeOptions";
import TipsComponent, {
  getCurrentTip,
} from "../../components/tipsComponent/TipsComponent";
import { Colors } from "../../constants/colors";
import { SiSamsungpay } from "react-icons/si";
import Logo from "../../components/Logo";
import InvoiceTable, { calcWithServiceFees } from "./InvoiceTable";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { Item } from "../../services/order";
import { toast } from "react-toastify";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import SplitBillModal from "../../components/SplitBill/SplitBillModal";
import landingPageImage from "../../assets/orninaLounge.jpg";
import MyRadio from "../../components/SmallComponents/MyRadio";
import { PiCreditCard } from "react-icons/pi";
import AuthModal from "../../components/AuthModal/AuthModal";
import {
  setOrder,
  setPaymentDue,
  setPaymentType,
  setPendingPayment,
  setSelectedMenuItems,
  setUsedCash,
} from "../../store/ducks/order";
import { MenuItem } from "../../models/API";
import ApplePayButton from "../../components/ApplePayButton";
import {
  encryptPaymentTrx,
  handleCCAvenuePaymentIFrame,
} from "../../services/CCAvenueService";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { GrGift } from "react-icons/gr";
import {
  setLoyaltyMood,
  setRegistered,
  setToken,
  setUser,
} from "../../store/ducks/app";
import { LamdaFnLinks, PaymentChannels } from "../../constants/enums";
import { PaymentOperationIds } from "../../constants/operationIds";
import { createPaymentRequestService } from "../../services/payment";
import CCAvenueModal from "../../components/CCAvenueModal/CCAvenueModal";
import S3URL from "../../constants/S3URL";
import { handleGetUserData } from "../../services/token";
import payWithSoSocial from "../../services/payWithSoSocial";
import {
  displayPrices,
  getEquivalentAED,
  isAllowedSplit,
  isDiscountItemLoyalty,
} from "../../helpers/utils";
import { getPendingPayments } from "../../services/pendingPayments";
import RedemptionModal from "../../components/RedemptionModal/RedemptionModal";
import {
  soSocialAppStore,
  soSocialGooglePlay,
} from "../../constants/constants";
const lightTheme = createTheme(lightThemeOptions);

const Invoice: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tip, total, order, paymentType, currentDue, selectedMenuItems } =
    useSelector((state: StoreState) => state.order);
  const table = useSelector((state: StoreState) => state.table.table)!;
  const user = useSelector((state: StoreState) => state.app.user);
  const { conceptOptions } = useSelector((state: StoreState) => state.app);
  const [open, setSplitBillOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state: StoreState) => state.app.token);
  const registered = useSelector((state: StoreState) => state.app.registered);
  const pendingPayments = useSelector(
    (state: StoreState) => state.order.pendingPayment
  );
  const usedCash = useSelector((state: StoreState) => state.order.usedCash);
  const initialTotal = useSelector((state: StoreState) => state.order.initial);
  const [selectedPayment, setSelectedPayment] = useState(
    detectDevice(navigator.userAgent) === "Unknown" ? "b" : "a"
  );
  const [splitBill, setSplitBill] = useState(false);
  const [authModelOpen, setAuthModelOpen] = useState(false);
  const [paymentModelOpen, setPaymentModelOpen] = useState(false);
  const [channel, setChannel] = useState("");
  const [paymentFrame, setPaymentFrame] = useState("");
  const [redemptionModalOpen, setRedemptionModalOpen] = useState(false);
  const payingWithSoSocialOnly = useMemo(
    () =>
      user &&
      displayPrices(getEquivalentAED(usedCash, user.soSocialConversion)) ===
        displayPrices(
          currentDue +
            calcWithServiceFees({
              total: currentDue,
              serviceFee: conceptOptions?.serviceFee!,
            }).serviceFee +
            tip
        ),
    [currentDue, usedCash, tip, conceptOptions?.serviceFee, user]
  );
  // console.log({ currentDue });

  const bankPayment = async () => {
    /* Order Validation */
    if (order?.Due === 0 || !order?.Due) {
      toast.error("The order has already been paid.");
      return;
    }
    if (currentDue === 0) {
      toast.error("Please enter an amount to pay.");
      return;
    }
    if (pendingPayments === null) {
      toast.error("Error Processing Your Request. Please Hold");
      return;
    }
    if (currentDue > order?.Due - pendingPayments) {
      toast.error(
        "Pending payment(s) in progress. Please Hold. You Can Pay " +
          displayPrices(order?.Due - pendingPayments) +
          "AED"
      );
      return;
    }
    try {
      setLoading(true);

      /* Prepare Simphony Payload */
      const items: Item[] | undefined = selectedMenuItems
        ? selectedMenuItems.map((item) => {
            return {
              DetailLink: item.DetailLink!,
              qty: item.SalesCount!,
            };
          })
        : undefined;

      const paymentMethod: string =
        selectedPayment === "a"
          ? detectDevice(navigator.userAgent) === "iOS"
            ? PaymentChannels.APPLE
            : PaymentChannels.SAMSUNG
          : PaymentChannels.CARD;
      setChannel(paymentMethod);
      const paymentDetails = {
        // SoSocial Info
        usedCash: usedCash,
        user: user ? user?._id : null,
        percentageArray: user?.percentageArray,
        soSocialConversion: user?.soSocialConversion,
        // Payment Info
        conceptId: table?.conceptID,
        tableSimphonyId: table?.simphonyID, // Table Name
        CheckNum: order?.CheckNumber!,
        CheckSeq: order?.CheckSeqNumber!,
        payment: {
          type: paymentType,
          amount: currentDue,
          billAmount: order?.Due!, // Total Due
          tips: getCurrentTip({
            tip,
            currentDue,
            total: order?.Due!,
          }),
          serviceCharge: displayPrices(
            calcWithServiceFees({
              total: currentDue,
              serviceFee: conceptOptions?.serviceFee!,
            }).serviceFee
          ),
          items,
          userEmail: user?.email,
          paymentMethod: paymentMethod,
        },
      };

      /* Create payment request */
      const paymentRequestID = await createPaymentRequestService(
        paymentDetails
      );

      /* Prepare payment transaction object */
      let host = window.location.hostname;
      const operation: string = PaymentOperationIds.CCAvenue_PAYMENT_STATUS;
      const redirectURL = `${
        LamdaFnLinks.PaymentOperations
      }?operation=${operation}&channel=${paymentMethod}&conceptID=${
        table?.conceptID
      }&source=${registered ? "app" : "website"}`;
      console.log({ redirectURL });
      let paymentTransaction: any = {
        merchant_id: "", // Will be add by server side

        order_id: order?.CheckId,
        currency: "AED",
        amount: displayPrices(
          currentDue +
            displayPrices(
              calcWithServiceFees({
                total: currentDue,
                serviceFee: conceptOptions?.serviceFee!,
              }).serviceFee
            ) +
            paymentDetails.payment.tips -
            getEquivalentAED(usedCash, user?.soSocialConversion)
        ),
        redirect_url: redirectURL,
        cancel_url: redirectURL,
        language: "EN",
        integration_type: "iframe_normal",

        // Additional Informations
        merchant_param1: host,
        merchant_param2: order?.id,
        merchant_param3: table.name,
        merchant_param4: table.tableCode,

        // Order Details
        merchant_param5: paymentRequestID,
      };
      // return;
      if (paymentMethod === PaymentChannels.SAMSUNG) {
        paymentTransaction.payment_option = "OPTSAMS";
      } else {
        paymentTransaction.payment_option = "OPTCRDC";
      }

      /* Call web service - Encrypt Payment Request */
      const bankResponse = await encryptPaymentTrx(
        conceptOptions,
        paymentTransaction
      );

      /* CCAvenue Request */
      const { access_code, encRequest } = bankResponse;

      /* non-seamless*/
      // await handleCCAvenuePayment(access_code, encRequest, channel);

      /* IFrame */
      const iFrame: string = await handleCCAvenuePaymentIFrame(
        "43366",
        access_code,
        encRequest
      );

      setPaymentFrame(iFrame);
      setPaymentModelOpen(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const payWithSoSocialCash = async () => {
    try {
      setLoading(true);
      const items: Item[] | undefined = selectedMenuItems
        ? selectedMenuItems.map((item) => {
            return {
              DetailLink: item.DetailLink!,
              qty: item.SalesCount!,
            };
          })
        : undefined;
      const paymentDetails = {
        // SoSocial Info
        usedCash: usedCash,
        user: user ? user?._id : null,
        percentageArray: user?.percentageArray,
        soSocialConversion: user?.soSocialConversion,
        // Payment Info
        conceptId: table?.conceptID,
        tableSimphonyId: table?.simphonyID, // Table Name
        CheckNum: order?.CheckNumber!,
        CheckSeq: order?.CheckSeqNumber!,
        payment: {
          type: paymentType,
          amount: currentDue,
          billAmount: order?.Due!, // Total Due
          tips: getCurrentTip({
            tip,
            currentDue,
            total: order?.Due!,
          }),
          serviceCharge: calcWithServiceFees({
            total: currentDue,
            serviceFee: conceptOptions?.serviceFee!,
          }).serviceFee,
          items,
          userEmail: user?.email,
          paymentMethod: "sosocial",
        },
      };
      /* Create payment request */
      const paymentRequestID = await createPaymentRequestService(
        paymentDetails
      );

      const paidSuccssfully = await payWithSoSocial({
        merchant_param2: order?.id,
        merchant_param3: table.name,
        merchant_param4: table.tableCode,
        merchant_param5: paymentRequestID,
        userName: user?.first_name + " " + user?.last_name,
        conceptID: table.conceptID,
        orderID: order?.id!,
        paymentDetailsRequestID: paymentRequestID,
        tableID: table.id,
        tableName: table.name,
      });

      if (paidSuccssfully.success) {
        // toast.success("Paid with SoSocial successfully");
        dispatch(setOrder(null));
        handleRemoveSoSocial();
        handleRemoveSplitBill();
        navigate(
          `/${
            paidSuccssfully.paymentID
          }/payment-status/?payment-request-id=${paymentRequestID}&order-id=${
            order?.CheckId
          }&dbOrderId=${order?.id}&tableid=${table.tableCode}&source=${
            registered ? "app" : "website"
          }`
        );
      } else {
        toast.error("Failed to pay with SoSocial");
      }
    } catch (error) {
      toast.error("Failed to pay with SoSocial");
    } finally {
      setLoading(false);
    }
  };

  function detectDevice(userAgent: string): "iOS" | "Android" | "Unknown" {
    if (
      userAgent.includes("iPhone") ||
      userAgent.includes("iPad") ||
      userAgent.includes("iPod")
    ) {
      return "iOS";
    } else if (userAgent.includes("Android")) {
      return "Android";
    } else {
      return "Unknown";
    }
  }

  const handleChangePayment = (choice: string) => {
    setSelectedPayment(choice);
  };

  const handleAuthModalOpen = (loyaltyMood: "earn" | "redeem" | null) => {
    dispatch(setLoyaltyMood(loyaltyMood));
    if (registered) {
      setRedemptionModalOpen(true);
      return;
    }
    setAuthModelOpen(true);
  };

  const handleRemoveSoSocial = () => {
    dispatch(setUsedCash(0));
    dispatch(setUser(undefined));
    dispatch(setToken(null));
    dispatch(setLoyaltyMood(null));
    dispatch(setRegistered(false));
  };

  const handleAuthModalClose = () => {
    setAuthModelOpen(false);
  };

  const handleSplitBill = () => {
    setSplitBillOpen(true);
  };

  const payFullHandler = () => {
    //reset any split bill changes here
    if (paymentType === "full") return;
    setSplitBill(false);
    dispatch(setPaymentDue(order?.Due!));
    dispatch(setPaymentType("full"));
    if (registered) {
      handleRemoveUsedCash();
    } else {
      handleRemoveSoSocial();
    }
  };

  const handleShowSplitBill = (
    value: number,
    selectedMenuItems?: MenuItem[]
  ) => {
    // console.log({ selectedMenuItems });
    setSplitBill(true);
    dispatch(setPaymentDue(value));
    dispatch(setPaymentType("partial"));
    dispatch(setSelectedMenuItems(selectedMenuItems));
    if (registered) {
      handleRemoveUsedCash();
    } else {
      handleRemoveSoSocial();
    }
  };

  const handleRemoveSplitBill = () => {
    setSplitBill(false);
    dispatch(setPaymentDue(order?.Due!));
    dispatch(setPaymentType("full"));
    dispatch(setSelectedMenuItems(undefined));
    if (registered) {
      handleRemoveUsedCash();
    } else {
      handleRemoveSoSocial();
    }
  };

  const handleRemoveUsedCash = () => {
    dispatch(setUsedCash(0));
    dispatch(setLoyaltyMood(null));
  };

  const getUsedCash = (cash: number) => {
    dispatch(setUsedCash(cash));
    setRedemptionModalOpen(false);
  };
  useEffect(() => {
    if (paymentType === "full") {
      dispatch(setPaymentDue(order?.Due!));
    }
  }, [order?.Due, dispatch, paymentType]);
  const [infoLoading, setInfoLoading] = useState(false);
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setInfoLoading(true);
        const response = await handleGetUserData(
          token,
          conceptOptions?.pos_id!
        );
        if (response.success) {
          dispatch(setUser(response.data));
        } else {
          throw new Error("Failed to get user data" + JSON.stringify(response));
        }
      } catch (error) {
        toast.error("error fetching user data");
        console.log(error);
      } finally {
        setInfoLoading(false);
      }
    };
    if (token && registered && !user) {
      getUserInfo();
    }
  }, []);

  const splitDisabled = () => {
    if (!order?.DiscountItems || order.DiscountItems.length === 0) return false;

    if (
      isDiscountItemLoyalty(
        order.DiscountItems,
        conceptOptions?.discountObjectNum
      )
    )
      return true;
    if (!isAllowedSplit(order.DiscountItems)) return true;
    console.log("Step 3");
    return false;
  };
  useEffect(() => {
    const handleGetPending = async () => {
      try {
        if (!order || !order.CheckNumber) return;
        const res = await getPendingPayments({
          CheckNum: order?.CheckNumber,
          tableId: table.tableCode,
          conceptID: table.conceptID,
        });
        dispatch(setPendingPayment(res?.totalPending));
      } catch (error) {
        console.log({ error });
        toast.error("Error accessing payments for this table");
      }
    };
    handleGetPending();
  }, []);
  console.log({
    currentDue,
    s: calcWithServiceFees({
      total: currentDue,
      serviceFee: conceptOptions?.serviceFee!,
    }).serviceFee,
    tip,
    cash: getEquivalentAED(usedCash, user?.soSocialConversion),
  });
  const handleDownloadClick = () => {
    if (detectDevice(navigator.userAgent) === "iOS") {
      window.open(soSocialAppStore, "_blank", "noopener,noreferrer");
    } else {
      window.open(soSocialGooglePlay, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: window.innerHeight,
          overflowX: "hidden",
          maxWidth: "100vw",
        }}
      >
        <AuthModal
          onClose={handleAuthModalClose}
          open={authModelOpen}
          setUsedCash={getUsedCash}
          earn={false}
        />
        <RedemptionModal
          open={redemptionModalOpen}
          onClose={() => setRedemptionModalOpen(false)}
          setCash={getUsedCash}
        />
        <CCAvenueModal
          onClose={() => {
            setPaymentModelOpen(false);
            setLoading(false);
          }}
          open={paymentModelOpen}
          action={paymentFrame}
          channel={channel}
        />

        <Grid container style={{ height: "100%", marginTop: "5%" }}>
          <Box
            component={"img"}
            src={
              conceptOptions?.mainBg
                ? `${S3URL}${conceptOptions.mainBg}`
                : landingPageImage
            }
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "52%",
              objectFit: "cover",
              objectPosition: "center",
              zIndex: -1,
            }}
          ></Box>
          <Box sx={classes.backButton}>
            <FaChevronLeft fontSize="inherit" onClick={() => navigate(-1)} />
          </Box>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "75px 75px 0px 0px",
              marginTop: "15%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                width: "130px",
                height: "130px",
                borderRadius: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "-70px",
                filter: "drop-shadow(0px 3px 6px #00000029)",
                overflow: "hidden",
              }}
            >
              <Logo
                color="primary"
                sx={{
                  cursor: "pointer",
                  width: "100%",
                }}
              />
            </Box>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              rowSpacing={4}
              sx={{ marginTop: "40px", px: "10px" }}
            >
              <Grid
                sx={{ display: "flex", justifyContent: "center", pl: "16px" }}
                item
                xs={10}
              >
                {conceptOptions?.EnableLoyalty && (
                  <Box
                    sx={{
                      fontSize: "13.5px",
                      color: lightTheme.palette.secondary.main,
                      mb: "5px",
                      "@media (max-width: 365px)": {
                        fontSize: "12.5px", // font size for screens 350px and below
                      },
                      "@media (max-width: 300px)": {
                        fontSize: "12px", // font size for screens 300px and below
                      },
                    }}
                  >
                    <Typography
                      fontSize={"inherit"}
                      sx={{ fontWeight: "medium" }}
                      textAlign={"center"}
                    >
                      {t(TranslationsEnum.earnCashbackLanding)}
                      <Typography
                        fontSize={"inherit"}
                        fontWeight={"bold"}
                        component={"span"}
                      >
                        &nbsp;
                        <Link
                          color="inherit"
                          underline="none"
                          onClick={handleDownloadClick}
                        >
                          SoSocial
                        </Link>
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
                paddingY={1}
                paddingX={"16px"}
                rowSpacing={2}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: lightTheme.palette.secondary.main,
                    }}
                  >
                    {t(TranslationsEnum.tableNo, {
                      secondsToWait: table.name,
                    })}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: lightTheme.palette.secondary.main,
                    }}
                  >
                    {t(TranslationsEnum.invoiceNumber, {
                      invoiceNumber: order?.CheckNumber,
                    })}
                  </Typography>
                </Box>
                <Typography
                  fontSize={10}
                  fontWeight={"medium"}
                  color={lightTheme.palette.secondary.main}
                >
                  {t(TranslationsEnum.allPricesAreIncluded)}
                </Typography>
              </Grid>

              {/* Ordered Items Table */}
              <Grid
                container
                sx={{ marign: 0, padding: 0 }}
                direction="column"
                // alignItems="center"
                rowSpacing={0}
              >
                {/* <OrderedItems /> */}
                <InvoiceTable />
                <Box
                  sx={{
                    px: "16px",
                    py: "10px",
                    color: lightTheme.palette.secondary.main,
                    borderBottom: "1px solid #f0f0f0",
                    paddingBottom: "0px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: "medium",
                      fontSize: "12px",
                    }}
                  >
                    <Typography fontSize={"inherit"} fontWeight={"inherit"}>
                      {t("Total")}
                    </Typography>
                    <Typography fontSize={"inherit"} fontWeight={"inherit"}>
                      <Typography
                        component={"span"}
                        fontSize={"inherit"}
                        fontWeight={"inherit"}
                      >
                        AED&nbsp;
                      </Typography>
                      {displayPrices(initialTotal).toFixed(2)}
                    </Typography>
                  </Box>

                  {!(
                    displayPrices(initialTotal).toFixed(2) ===
                    displayPrices(
                      order?.Due! +
                        calcWithServiceFees({
                          total: order?.Due!,
                          serviceFee: conceptOptions?.serviceFee!,
                        }).serviceFee
                    ).toFixed(2)
                  ) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: "medium",
                        fontSize: "12px",
                      }}
                    >
                      <Typography fontSize={"inherit"} fontWeight={"inherit"}>
                        Due
                      </Typography>
                      <Typography fontSize={"inherit"} fontWeight={"inherit"}>
                        <Typography
                          component={"span"}
                          fontSize={"inherit"}
                          fontWeight={"inherit"}
                        >
                          AED&nbsp;
                        </Typography>
                        {displayPrices(
                          order?.Due! +
                            calcWithServiceFees({
                              total: order?.Due!,
                              serviceFee: conceptOptions?.serviceFee!,
                            }).serviceFee
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      alignItems: "center",
                      mt: "10px",
                      textTransform: "none",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleSplitBill}
                      sx={{
                        ...classes.splitButton,
                        border:
                          paymentType === "partial"
                            ? "1px solid #C4C4C4 !important"
                            : undefined,
                        backgroundColor:
                          paymentType === "partial" ? "white" : "#FAFAFA",
                        color:
                          paymentType === "partial"
                            ? lightTheme.palette.secondary.main
                            : lightTheme.palette.secondary.main,
                        // fontWeight:
                        //   paymentType === "partial" ? "bold" : undefined,
                        "&:hover": {
                          backgroundColor:
                            paymentType === "partial" ? "white" : "#FAFAFA",
                          color:
                            paymentType === "partial"
                              ? lightTheme.palette.secondary.main
                              : lightTheme.palette.secondary.main,
                          border: "1px solid #C4C4C4",
                          boxShadow: "none",
                        },
                        textTransform: "none",
                      }}
                      disabled={splitDisabled()}
                    >
                      {paymentType === "partial" ? "Edit Split" : "Split Bill"}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        ...classes.payFull,
                        border:
                          paymentType === "full"
                            ? "1px solid #C4C4C4 !important"
                            : undefined,
                        backgroundColor:
                          paymentType === "full" ? "white" : "#FAFAFA",
                        color:
                          paymentType === "full"
                            ? lightTheme.palette.secondary.main
                            : lightTheme.palette.secondary.main,
                        // fontWeight: paymentType === "full" ? "bold" : undefined,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor:
                            paymentType === "full" ? "white" : "#FAFAFA",
                          color:
                            paymentType === "full"
                              ? lightTheme.palette.secondary.main
                              : lightTheme.palette.secondary.main,
                          border: "1px solid #C4C4C4",
                          boxShadow: "none",
                        },
                      }}
                      onClick={payFullHandler}
                    >
                      Pay Full
                    </Button>
                  </Box>
                </Box>
              </Grid>

              {/* Add a Tip */}
              <TipsComponent />

              {/* Payable Amount */}
              {!usedCash && (
                <Grid
                  container
                  direction="column"
                  rowSpacing={2}
                  sx={{ mt: "5px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                      px: "16px",
                    }}
                  >
                    <Box>
                      {!splitBill ? (
                        <>
                          <Typography
                            fontSize={12}
                            color={lightTheme.palette.secondary.main}
                            fontWeight={"600"}
                          >
                            {t(TranslationsEnum.TotalAmount)}
                          </Typography>
                          <Typography
                            fontSize={10}
                            // color={"#c3c3c3"}
                            sx={{ color: "#c3c3c3" }}
                            fontWeight={"medium"}
                          >
                            {t(TranslationsEnum.includesTip)}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            fontSize={12}
                            fontWeight={"medium"}
                            color={lightTheme.palette.secondary.main}
                          >
                            Amount (Your Share)
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box>
                      <Typography
                        fontSize={12}
                        color={lightTheme.palette.secondary.main}
                        fontWeight={"600"}
                      >
                        <Typography
                          component={"span"}
                          fontSize={12}
                          fontWeight={"medium"}
                        >
                          AED&nbsp;
                        </Typography>
                        {displayPrices(
                          currentDue +
                            calcWithServiceFees({
                              total: currentDue,
                              serviceFee: conceptOptions?.serviceFee!,
                            }).serviceFee +
                            getCurrentTip({
                              tip,
                              total: order?.Due!,
                              currentDue,
                            })
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
              {/* Loyalty */}
              {conceptOptions?.EnableLoyalty && (
                <Grid
                  container
                  direction="column"
                  rowSpacing={2}
                  sx={{
                    mt: "10px",
                    px: "16px",
                    pb: "10px",
                    borderBottom: "1px solid #f0f0f0",
                  }}
                >
                  {user && (
                    <>
                      {!Boolean(usedCash) && (
                        // authenticated and earning
                        <>
                          <Box sx={classes.earnedWithSoSocial}>
                            <Box
                              sx={{
                                backgroundColor: "white",
                                p: "12px",
                                width: "100%",
                                borderRadius: "32px",
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "absolute",
                                  left: "9px",
                                  top: "50%",
                                  transform: "translate(-0%, -50%)",
                                }}
                              >
                                <svg width="0" height="0">
                                  <defs>
                                    <linearGradient
                                      id="custom-gradient"
                                      gradientTransform="rotate(45)"
                                    >
                                      <stop
                                        offset="0%"
                                        stopColor="rgba(207,58,113,1)"
                                      />
                                      <stop
                                        offset="50%"
                                        stopColor="rgba(252,216,40,1)"
                                      />
                                      <stop
                                        offset="100%"
                                        stopColor="rgba(159,199,50,1)"
                                      />
                                    </linearGradient>
                                  </defs>
                                </svg>
                                <GrGift
                                  style={{
                                    stroke: "url(#custom-gradient)",
                                    strokeWidth: "2px",
                                    fontSize: "21px",
                                  }}
                                />
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  width: "85%",
                                  textWrap: "wrap",
                                  marginX: "auto",
                                }}
                              >
                                {" "}
                                {t(TranslationsEnum.earnedWithSoSocial, {
                                  cash: displayPrices(
                                    (currentDue +
                                      calcWithServiceFees({
                                        total: currentDue,
                                        serviceFee: conceptOptions?.serviceFee!,
                                      }).serviceFee) *
                                      (user.percentageArray / 100 || 1)
                                  ).toFixed(2),
                                })}
                              </Typography>
                            </Box>
                          </Box>
                          {!usedCash && user && registered && !infoLoading && (
                            <>
                              <Button
                                onClick={() => handleAuthModalOpen("redeem")}
                                variant={"contained"}
                                sx={classes.earnWithSoSocial}
                              >
                                {t(TranslationsEnum.earnWithSoSocial)}
                              </Button>
                            </>
                          )}
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Typography fontSize={"12px"}>
                              Authenticated successfully!&nbsp;
                              <Typography
                                fontSize={"12px"}
                                fontWeight={"bold"}
                                component={"span"}
                              >
                                {user.first_name + " " + user.last_name}
                              </Typography>
                            </Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Typography fontSize={"12px"}>
                              Existing SoSocial Wallet Cash&nbsp;
                              <Typography
                                fontSize={"12px"}
                                fontWeight={"bold"}
                                component={"span"}
                              >
                                {displayPrices(
                                  Number(user.wallet.amount)
                                ).toFixed(2)}
                              </Typography>
                            </Typography>
                          </Box>
                        </>
                      )}
                      {Boolean(usedCash) && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mb: "10px",
                          }}
                        >
                          <Typography
                            fontSize={12}
                            fontWeight={"medium"}
                            color={lightTheme.palette.secondary.main}
                          >
                            Amount Was {splitBill && "(Your Share)"}
                          </Typography>
                          <Typography
                            fontSize={12}
                            fontWeight={"medium"}
                            color={lightTheme.palette.secondary.main}
                            sx={{
                              textDecorationStyle: "solid",
                              textDecoration: "line-through",
                              textDecorationColor: "#606060",
                              textDecorationThickness: "1px",
                            }}
                          >
                            <Typography
                              component={"span"}
                              fontSize={12}
                              fontWeight={"medium"}
                            >
                              AED&nbsp;
                            </Typography>
                            {displayPrices(
                              currentDue +
                                calcWithServiceFees({
                                  total: currentDue,
                                  serviceFee: conceptOptions?.serviceFee!,
                                }).serviceFee +
                                getCurrentTip({
                                  tip,
                                  total: order?.Due!,
                                  currentDue,
                                })
                            ).toFixed(2)}
                          </Typography>
                        </Box>
                      )}
                      {Boolean(usedCash) && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mb: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "start",
                                gap: "10px",
                              }}
                            >
                              <Typography
                                fontSize={12}
                                fontWeight={"bold"}
                                color={lightTheme.palette.secondary.main}
                              >
                                SoSocial Redemption, You Saved
                              </Typography>
                              <Box
                                sx={{
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "3px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                  border:
                                    "1px solid " +
                                    lightTheme.palette.secondary.main,
                                  borderRadius: "50%",
                                }}
                                onClick={handleRemoveUsedCash}
                              >
                                <CloseOutlinedIcon
                                  sx={{
                                    color: lightTheme.palette.secondary.main,
                                  }}
                                  fontSize="inherit"
                                />
                              </Box>
                            </Box>
                            <Typography
                              fontSize={12}
                              fontWeight={"medium"}
                              color={lightTheme.palette.secondary.main}
                            >
                              -{" "}
                              <Typography
                                component={"span"}
                                fontSize={12}
                                fontWeight={"medium"}
                              >
                                AED&nbsp;
                              </Typography>
                              {displayPrices(
                                getEquivalentAED(
                                  usedCash,
                                  user.soSocialConversion
                                )
                              ).toFixed(2)}
                            </Typography>
                          </Box>
                        </>
                      )}
                      {(splitBill || Boolean(usedCash)) && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Typography
                              fontSize={12}
                              color={lightTheme.palette.secondary.main}
                              fontWeight={"medium"}
                            >
                              {t(TranslationsEnum.TotalDiscountedAmount)}
                            </Typography>
                            <Typography
                              fontSize={10}
                              // color={lightTheme.palette.secondary.main}
                              fontWeight={"medium"}
                              sx={{ color: "#c3c3c3" }}
                            >
                              {t(TranslationsEnum.includesTip)}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize={12}
                              color={lightTheme.palette.secondary.main}
                              fontWeight={"medium"}
                            >
                              <Typography
                                component={"span"}
                                fontSize={12}
                                fontWeight={"medium"}
                              >
                                AED&nbsp;
                              </Typography>
                              {displayPrices(
                                currentDue +
                                  calcWithServiceFees({
                                    total: currentDue,
                                    serviceFee: conceptOptions?.serviceFee!,
                                  }).serviceFee +
                                  tip -
                                  getEquivalentAED(
                                    usedCash,
                                    user.soSocialConversion
                                  )
                              ).toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                  {infoLoading && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    </>
                  )}
                  {/* COMMENTED FOR NOW - SoSocial */}
                  {!usedCash && !user && !infoLoading && (
                    <>
                      <Button
                        onClick={() => handleAuthModalOpen("earn")}
                        variant={"contained"}
                        sx={classes.payWithSoSocial}
                      >
                        <Box sx={{ width: "100%" }}>
                          {" "}
                          <GrGift
                            style={{
                              position: "absolute",
                              left: "23px",
                              fontSize: "21px",
                              top: "50%",
                              transform: "translate(-0%, -50%)",
                              color: "#231f20",
                            }}
                          />
                          {t(TranslationsEnum.payWithSoSocial)}
                        </Box>
                      </Button>
                    </>
                  )}
                  {!usedCash && !user && !infoLoading && (
                    <>
                      <Button
                        onClick={() => handleAuthModalOpen("redeem")}
                        variant={"contained"}
                        sx={classes.earnWithSoSocial}
                      >
                        {t(TranslationsEnum.earnWithSoSocial)}
                      </Button>
                    </>
                  )}
                </Grid>
              )}
              <Grid
                container
                direction="column"
                rowSpacing={2}
                sx={{ mt: "10px", px: "16px" }}
              >
                <Typography
                  fontSize={15}
                  color={lightTheme.palette.secondary.main}
                  fontWeight={"bold"}
                  marginBottom={"5px"}
                >
                  {t(TranslationsEnum.paymentMethod)}
                </Typography>
                {/* Payment Section */}
                {["no"].filter((s) => {}).toString()}
                {!payingWithSoSocialOnly && (
                  <Box>
                    {detectDevice(navigator.userAgent) !== "Unknown" && (
                      <Box
                        sx={{
                          backgroundColor:
                            selectedPayment === "a" ? "#D0D0D0" : "unset",
                          borderRadius: "32px",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #D0d0d0",
                          marginBottom: "5px",
                        }}
                        onClick={() => handleChangePayment("a")}
                      >
                        <MyRadio
                          checked={selectedPayment === "a"}
                          onChange={() => handleChangePayment("a")}
                          value="a"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                        {detectDevice(navigator.userAgent) === "Android" ? (
                          <>
                            <Typography
                              fontSize={12}
                              color={lightTheme.palette.secondary.main}
                              fontWeight={"medium"}
                            >
                              {t(TranslationsEnum.samsungPay)}
                            </Typography>
                            <SiSamsungpay
                              fontSize={42}
                              style={{
                                marginRight: "10px",
                                marginLeft: "auto",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Typography
                              fontSize={12}
                              color={lightTheme.palette.secondary.main}
                              fontWeight={"medium"}
                            >
                              {t("Pay")}
                            </Typography>
                            <AppleIcon
                              style={{ marginRight: "8px", marginLeft: "auto" }}
                            />
                          </>
                        )}
                      </Box>
                    )}
                    <Box
                      sx={{
                        backgroundColor:
                          selectedPayment === "b" ? "#D0D0D0" : "unset",
                        borderRadius: "32px",
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #D0d0d0",
                      }}
                      onClick={() => handleChangePayment("b")}
                    >
                      <MyRadio
                        checked={selectedPayment === "b"}
                        onChange={() => handleChangePayment("b")}
                        value="b"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "B" }}
                      />
                      <Typography
                        fontSize={12}
                        color={lightTheme.palette.secondary.main}
                        fontWeight={"medium"}
                      >
                        {t("CardPay")}
                      </Typography>
                      <PiCreditCard
                        style={{
                          marginRight: "8px",
                          marginLeft: "auto",
                          width: "24px",
                          height: "24px",
                          fontSize: "24px",
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid
                container
                direction="column"
                rowSpacing={2}
                sx={{ mt: "10px", px: "16px", mb: "20px" }}
              >
                {payingWithSoSocialOnly ? (
                  <Button
                    startIcon={loading && <CircularProgress size={20} />}
                    sx={{
                      ...classes.finalPayment,
                      background:
                        "linear-gradient(135deg, rgba(207,58,113,1) 0%, rgba(252,216,40,1) 50%, rgba(159,199,50,1) 100%)",
                      color: "#000",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                    onClick={payWithSoSocialCash}
                    disabled={loading}
                  >
                    <>Pay With SoSocial</>
                  </Button>
                ) : selectedPayment === "b" ? (
                  <Button
                    startIcon={loading && <CircularProgress size={20} />}
                    sx={classes.finalPayment}
                    onClick={bankPayment}
                    disabled={loading}
                  >
                    {t(TranslationsEnum.payWithCredit)}
                  </Button>
                ) : detectDevice(navigator.userAgent) === "Android" ? (
                  <Button
                    startIcon={loading && <CircularProgress size={20} />}
                    sx={classes.finalPayment}
                    onClick={bankPayment}
                    disabled={loading}
                  >
                    <>
                      Pay With
                      <SiSamsungpay
                        fontSize={36}
                        style={{ marginLeft: "5px" }}
                      />
                    </>
                  </Button>
                ) : (
                  detectDevice(navigator.userAgent) === "iOS" && (
                    /* Apple Pay Button */
                    <ApplePayButton></ApplePayButton>
                  )
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <SplitBillModal
          open={open}
          onClose={() => setSplitBillOpen(false)}
          handleSuccessfull={handleShowSplitBill}
          handleRemoveSplitBill={handleRemoveSplitBill}
        />
      </div>

      {/* Customer Name Modal */}
      {/* <Dialog
        open={open}
        onClose={() => setSplitBill(false)}
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogContent>
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            color="error"
            onClick={() => setSplitBill(false)}
          >
            <ClearIcon />
          </IconButton>

          <Typography
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {t("EnterName")}
          </Typography>

          <DialogContentText
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              size="small"
              value={customerName}
              placeholder={t("EnterYourName")}
              onChange={handleCustomerNameChange}
              style={{ marginBottom: "10px" }}
            />

            <Button
              variant="contained"
              color="primary"
              style={{
                height: "36px",
                borderRadius: "10px",
              }}
              onClick={() => {
                handleConfirmName();
              }}
            >
              {t("Confirm")}
            </Button>
            {error.name && (
              <Typography
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                {t("enterNamePrompt")}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
    </>
  );
};

export default Invoice;

const classes = {
  Header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  backButton: {
    cursor: "pointer",
    position: "absolute",
    left: "26px",
    color: "#603813",
    backgroundColor: "white",
    borderRadius: "10px",
    fontSize: "28px",
    width: "36px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  appleButton: {
    height: "36px",
    background: Colors.SECONDARY,
    color: "white",
    borderRadius: "20px",
    width: "100%",
    display: "flex", // Make the button a flex container
    justifyContent: "center", // Center the content horizontally
    alignItems: "center", // Center the content vertically
    "&:hover": {
      background: Colors.SECONDARY_DARK,
      color: "white",
    },
  },
  samsungButton: {
    height: "36px",
    background: Colors.SAMSUNG_PAY,
    color: "white",
    borderRadius: "20px",
    width: "100%",
    display: "flex", // Make the button a flex container
    justifyContent: "center", // Center the content horizontally
    alignItems: "center", // Center the content vertically
    "&:hover": {
      background: Colors.SAMSUNG_PAY_DARKER,
      color: "white",
    },
  },
  membershipButton: {
    height: "49px",
    // padding: "0 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    fontSize: window.innerWidth >= 370 ? "16px" : "12px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#FFFFFFFF",
    background: "#BCAF98FF",
    opacity: 1,
    border: "none",
    borderRadius: "11px",
    width: "100%",
    "&:hover": {
      color: "#FFFFFFFF",
      background: "#A28F70FF",
    },
    "&:active": {
      color: "#FFFFFFFF",
      background: "#827155FF",
    },
    "&:disabled": {
      opacity: 0.4,
    },
  },
  billTitle: {
    fontFamily: "Inter",
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "34px",
    color: "#171A1FFF",
  },
  splitButton: {
    width: "45%",
    backgroundColor: "#FAFAFA",
    borderRadius: "32px",
    boxShadow: "none",
    py: "10px",
    color: lightTheme.palette.secondary.main,
    fontWeight: "medium",
    fontSize: "12px",
    border: "1px solid white",
  },
  payFull: {
    width: "45%",
    backgroundColor: "#FAFAFA",
    borderRadius: "32px",
    boxShadow: "none",
    py: "10px",
    color: lightTheme.palette.secondary.main,
    fontWeight: "medium",
    fontSize: "12px",
    border: "1px solid white",
  } as SxProps,
  payWithSoSocial: {
    // backgroundColor: "black",
    background:
      "linear-gradient(135deg, rgba(207,58,113,1) 0%, rgba(252,216,40,1) 50%, rgba(159,199,50,1) 100%)",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    py: "10px",
    width: "100%",
    color: "black",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
  earnedWithSoSocial: {
    // backgroundColor: "black",
    background:
      "linear-gradient(135deg, rgba(207,58,113,1) 0%, rgba(252,216,40,1) 50%, rgba(159,199,50,1) 100%)",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    p: "1px",
    width: "100%",
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
  earnWithSoSocial: {
    mx: "auto",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    mt: "8px",
    boxShadow: "none",
    // width: "70%",
    py: "10px",
    color: "#5F5D5D",
    border: "1px solid #C4C4C4",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    "&:disabled": {
      backgroundColor: "white",
      color: "#3a3a3a",
    },
  },
  finalPayment: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  } as SxProps,
};
