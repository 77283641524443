export enum MoneyTypes {
  PERCENTAGE = "percentage",
  FIXED = "fixed",
}
export enum LamdaFnLinks {
  TableManagement = "https://xvon7mvcoplfwzgrpehrhufdoq0dmqta.lambda-url.us-east-2.on.aws/",
  SimphonyOperations = "https://uxhykzgzeqma4mew7bvmbbpfnq0iaoqt.lambda-url.us-east-2.on.aws/",
  PaymentOperations = "https://qh2g3lodfgrd227miwjwbvps3q0zpiho.lambda-url.us-east-2.on.aws/",
  LoyaltyOperations = "https://vxwdte5swxua4ineics3ia2vm40mvtgr.lambda-url.us-east-2.on.aws/",
}

export enum TableStatus {
  OCCUPIED = "occupied",
  AVAILABLE = "available",
  RESERVED = "reserved",
  UNAVAILABLE = "unavailable",
}

export enum PaymentChannels {
  APPLE = "applePay",
  SAMSUNG = "samsungPay",
  CARD = "cardPay",
}
